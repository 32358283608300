import axios from "axios";

const REPORTS_GET_REQUEST = 'GET_REPORTS_GET_REQUEST';
const REPORTS_GET_SUCCESS = 'GET_REPORTS_GET_SUCCESS';
const REPORTS_GET_FAILURE = 'GET_REPORTS_GET_FAILURE';

const COMPLIANCE_PIE_CHART_REQUEST = 'COMPLIANCE_PIE_CHART_REQUEST';
const COMPLIANCE_PIE_CHART_SUCCESS = 'COMPLIANCE_PIE_CHART_SUCCESS';
const COMPLIANCE_PIE_CHART_FAILURE = 'COMPLIANCE_PIE_CHART_FAILURE';

const COMPLIES_PIE_CHART_REQUEST = 'COMPLIES_PIE_CHART_REQUEST';
const COMPLIES_PIE_CHART_SUCCESS = 'COMPLIES_PIE_CHART_SUCCESS';
const COMPLIES_PIE_CHART_FAILURE = 'COMPLIES_PIE_CHART_FAILURE';

const BREACHES_PIE_CHART_REQUEST = 'BREACHES_PIE_CHART_REQUEST';
const BREACHES_PIE_CHART_SUCCESS = 'BREACHES_PIE_CHART_SUCCESS';
const BREACHES_PIE_CHART_FAILURE = 'BREACHES_PIE_CHART_FAILURE';

const COMPLIANCE_BAR_GRAPH_REQUEST = 'COMPLIANCE_BAR_GRAPH_REQUEST';
const COMPLIANCE_BAR_GRAPH_SUCCESS = 'COMPLIANCE_BAR_GRAPH_SUCCESS';
const COMPLIANCE_BAR_GRAPH_FAILURE = 'COMPLIANCE_BAR_GRAPH_FAILURE';

const COMPLIANCE_TIMELINE_GRAPH_REQUEST = 'COMPLIANCE_TIMELINE_GRAPH_REQUEST';
const COMPLIANCE_TIMELINE_GRAPH_SUCCESS = 'COMPLIANCE_TIMELINE_GRAPH_SUCCESS';
const COMPLIANCE_TIMELINE_GRAPH_FAILURE = 'COMPLIANCE_TIMELINE_GRAPH_FAILURE';

const PENALTY_GRAPH_REQUEST = 'PENALTY_GRAPH_REQUEST';
const PENALTY_GRAPH_SUCCESS = 'PENALTY_GRAPH_SUCCESS';
const PENALTY_GRAPH_FAILURE = 'PENALTY_GRAPH_FAILURE';

const BREACHES_GRAPH_REQUEST = 'BREACHES_GRAPH_REQUEST';
const BREACHES_GRAPH_SUCCESS = 'BREACHES_GRAPH_SUCCESS';
const BREACHES_GRAPH_FAILURE = 'BREACHES_GRAPH_FAILURE';

const DEPARTMENT_REQUEST = 'DEPARTMENT_REQUEST';
const DEPARTMENT_SUCCESS = 'DEPARTMENT_SUCCESS';
const DEPARTMENT_FAILURE = 'DEPARTMENT_FAILURE';

const DEPARTMENT_BREACHES_REQUEST = 'DEPARTMENT_BREACHES_REQUEST';
const DEPARTMENT_BREACHES_SUCCESS = 'DEPARTMENT_BREACHES_SUCCESS';
const DEPARTMENT_BREACHES_FAILURE = 'DEPARTMENT_BREACHES_FAILURE';

const SOURCE_DOCUMENT_REQUEST = 'SOURCE_DOCUMENT_REQUEST';
const SOURCE_DOCUMENT_SUCCESS = 'SOURCE_DOCUMENT_SUCCESS';
const SOURCE_DOCUMENT_FAILURE = 'SOURCE_DOCUMENT_FAILURE';

const init = {
  loading: false,
  errors: false,
  compliancePieChart: {
    labels: ["Complied", "Pending", "Breached",],
    values: [1,1,1],
    title : "Compliance"
  },
  breachesPieChart: {
    labels: ["Approved", "Pending", "Rejected",],
    values: [1,1,1],
    title : "Breaches"
  },
  compliesPieChart: {
    labels: ["Approved", "Pending", "Rejected",],
    values: [1,1,1],
    title : "Complies"
  },
  timelineCompliance :false,
  department_breaches: false,
  penalties: false,
  breaches: false,
  department: false,
  source_document: false,
}


export const ComplianceReports = (state = init, action) => {
  switch (action.type){
    case REPORTS_GET_REQUEST : {return {...state, loading : true, errors: false}}
    case REPORTS_GET_SUCCESS : {return {...state, loading : false,
      compliancePieChart: action.payload.compliancePieChart,
      barGraph: action.payload.barGraph,
      penalties: action.payload.penalties,
      breaches: action.payload.breaches}} // TODO COLLECT ALL
    case REPORTS_GET_FAILURE : {return {...state, loading : false, errors: action.payload}}

    case COMPLIANCE_PIE_CHART_REQUEST : {return {...state, loading : "compliancePieChart", errors: false}}
    case COMPLIANCE_PIE_CHART_SUCCESS : {return {...state, loading : false, compliancePieChart: action.payload}}
    case COMPLIANCE_PIE_CHART_FAILURE : {return {...state, loading : false, errors: action.payload}}

    case BREACHES_PIE_CHART_REQUEST : {return {...state, loading : "breachesPieChart", errors: false}}
    case BREACHES_PIE_CHART_SUCCESS : {return {...state, loading : false, breachesPieChart: action.payload}}
    case BREACHES_PIE_CHART_FAILURE : {return {...state, loading : false, errors: action.payload}}

    case COMPLIES_PIE_CHART_REQUEST : {return {...state, loading : "compliesPieChart", errors: false}}
    case COMPLIES_PIE_CHART_SUCCESS : {return {...state, loading : false, compliesPieChart: action.payload}}
    case COMPLIES_PIE_CHART_FAILURE : {return {...state, loading : false, errors: action.payload}}

    case COMPLIANCE_TIMELINE_GRAPH_REQUEST : {return {...state, loading : "timelineCompliance", errors: false}}
    case COMPLIANCE_TIMELINE_GRAPH_SUCCESS : {return {...state, loading : false, timelineCompliance: action.payload}}
    case COMPLIANCE_TIMELINE_GRAPH_FAILURE : {return {...state, loading : false, errors: action.payload}}

    case PENALTY_GRAPH_REQUEST : {return {...state, loading : "penalties", errors: false}}
    case PENALTY_GRAPH_SUCCESS : {return {...state, loading : false, penalties: action.payload}}
    case PENALTY_GRAPH_FAILURE : {return {...state, loading : false, errors: action.payload}}

    case BREACHES_GRAPH_REQUEST : {return {...state, loading : "breaches", errors: false}}
    case BREACHES_GRAPH_SUCCESS : {return {...state, loading : false, breaches: action.payload}}
    case BREACHES_GRAPH_FAILURE : {return {...state, loading : false, errors: action.payload}}

    case DEPARTMENT_REQUEST : {return {...state, loading : "department", errors: false}}
    case DEPARTMENT_SUCCESS : {return {...state, loading : false, department: action.payload}}
    case DEPARTMENT_FAILURE : {return {...state, loading : false, errors: action.payload}}

    case DEPARTMENT_BREACHES_REQUEST : {return {...state, loading : "department_breaches", errors: false}}
    case DEPARTMENT_BREACHES_SUCCESS : {return {...state, loading : false, department_breaches: action.payload}}
    case DEPARTMENT_BREACHES_FAILURE : {return {...state, loading : false, errors: action.payload}}

    case SOURCE_DOCUMENT_REQUEST : {return {...state, loading : "source_document", errors: false}}
    case SOURCE_DOCUMENT_SUCCESS : {return {...state, loading : false, source_document: action.payload}}
    case SOURCE_DOCUMENT_FAILURE : {return {...state, loading : false, errors: action.payload}}

    default : {return {...state}}
  }

}

export const getCompliancePieChart = () => dispatch => {
  dispatch({type : COMPLIANCE_PIE_CHART_REQUEST});
  axios.get('app/reports/compliance/pie-chart')
    .then(({data}) => dispatch({type: COMPLIANCE_PIE_CHART_SUCCESS, payload: data}))
    .catch(({message}) => dispatch({type: COMPLIANCE_PIE_CHART_FAILURE, payload: message}))
}

export const getBreachPieChart = () => dispatch => {
  dispatch({type : BREACHES_PIE_CHART_REQUEST});
  axios.get('app/reports/breach/pie-chart')
    .then(({data}) => dispatch({type: BREACHES_PIE_CHART_SUCCESS, payload: data}))
    .catch(({message}) => dispatch({type: BREACHES_PIE_CHART_FAILURE, payload: message}))
}

export const getComplyPieChart = () => dispatch => {
  dispatch({type : COMPLIES_PIE_CHART_REQUEST});
  axios.get('app/reports/comply/pie-chart')
    .then(({data}) => dispatch({type: COMPLIES_PIE_CHART_SUCCESS, payload: data}))
    .catch(({message}) => dispatch({type: COMPLIES_PIE_CHART_FAILURE, payload: message}))
}

export const getTimelineCompliance = (params) => dispatch => {
  dispatch({type : COMPLIANCE_TIMELINE_GRAPH_REQUEST});
  axios.get('app/reports/compliance/timeline', {params})
    .then(({data}) => dispatch({type: COMPLIANCE_TIMELINE_GRAPH_SUCCESS, payload: data}))
    .catch(({message}) => dispatch({type: COMPLIANCE_TIMELINE_GRAPH_FAILURE, payload: message}))
}

export const getPenalties = () => dispatch => {
  dispatch({type : PENALTY_GRAPH_REQUEST});
  axios.get('app/reports/breaches/penalties-graph')
    .then(({data}) => dispatch({type: PENALTY_GRAPH_SUCCESS, payload: data}))
    .catch(({message}) => dispatch({type: PENALTY_GRAPH_FAILURE, payload: message}))
}

export const getBreaches = () => dispatch => {
  dispatch({type : BREACHES_GRAPH_REQUEST});
  axios.get('app/reports/breaches/graph')
    .then(({data}) => dispatch({type: BREACHES_GRAPH_SUCCESS, payload: data}))
    .catch(({message}) => dispatch({type: BREACHES_GRAPH_FAILURE, payload: message}))
}

export const getDepartments = () => dispatch => {
  dispatch({type : DEPARTMENT_REQUEST});
  axios.get('app/reports/department')
    .then(({data}) => dispatch({type: DEPARTMENT_SUCCESS, payload: data}))
    .catch(({message}) => dispatch({type: DEPARTMENT_FAILURE, payload: message}))
}

export const getSourceDocument = () => dispatch => {
  dispatch({type : SOURCE_DOCUMENT_REQUEST});
  axios.get('app/reports/source/document')
    .then(({data}) => dispatch({type: SOURCE_DOCUMENT_SUCCESS, payload: data}))
    .catch(({message}) => dispatch({type: SOURCE_DOCUMENT_FAILURE, payload: message}))
}

export const getDepartmentBreaches = () => dispatch => {
  dispatch({type : DEPARTMENT_BREACHES_REQUEST});
  axios.get('app/reports/department/breaches')
    .then(({data}) => dispatch({type: DEPARTMENT_BREACHES_SUCCESS, payload: data}))
    .catch(({message}) => dispatch({type: DEPARTMENT_BREACHES_FAILURE, payload: message}))
}



